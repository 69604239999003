

.circleContainer {
    background: #ccc;

    padding: 10px;

    border-radius: 50%;
}
.circleContainer img {
    height: 30px;
    width: 35px;
}

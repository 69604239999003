
.container {
    width: calc(100% - 20px);
    
    height: 3em;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 15px;
}


.input {
    box-sizing: border-box;

    flex: 1;

    padding: 5px;

    border-radius: 10px;
    /* border: solid 1px black; */
    border: none !important;

    box-shadow: inset 0px 0px 20px 5px rgba(0,0,0,0.15);

    background: var(--background-color);
    color: var(--foreground-color);

    font-family: inherit;

    resize: none;
}
.input:active {
    border: none;
}


.container button {
    background: none;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    background: var(--primary-color);

    border-radius: 50%;

    height: 30px;
    width: 30px;
}

.container button img {
    filter: invert();

    height: 60%;
}


.container {
    box-sizing: border-box;

    position: relative;
    
    height: 35em;
    width: 20em;

    background-color: var(--background-color-tertiary);

    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* border: 1px solid black; */

    -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.2);

    pointer-events: auto;
}
.container:after {
    content: '';

    position: absolute;
    
    top: 100%;
    right: 15px;
    
    width: 0;
    height: 0;
    
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--background-color);
    
    clear: both;
}


.header {
    box-sizing: border-box;

    width: 100%;
    height: 1em;

    gap: 0.3em;

    padding: 20px 5px;

    top: 0;

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    /* background: var(--primary-color); */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    user-select: none;
    
    color: var(--foreground-color);
}
.header h4 {
    padding: 0;
    margin: 0;

    font-size: 0.9em;

    font-weight: 600;
}
.header img {
    /* filter: invert(); */
}
.header > button {
    background: none;

    border: none;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.subHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 5px;
}


.contentContainer {
    flex-grow: 1;

    height: 100%;
    width: 100%;

    overflow: hidden;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contentWindow {
    box-sizing: border-box;

    /* height: calc(100% - 20px); */
    height: 100%;
    width: calc(100% - 10px);

    border-radius: 10px;
    padding: 0.75em 5px;

    /* background: #222; */
    background: var(--background-color);

    overflow-y: auto;

    display: flex;
    flex-direction: column-reverse;

    gap: 0.75em;
}

.topInfo {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 10px 0;

    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

    color: var(--foreground-color);
}
.topInfo p {
    font-size: 0.75rem;

    margin: 0;
    padding: 0;

    margin-top: 3px;

    font-weight: 600;
}


.contentWindow::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}
  
.contentWindow::-webkit-scrollbar-track {
    background: var(--background-color-secondary);        /* color of the tracking area */
    border-radius: 5px;
    margin: 5px 0px;
}

.contentWindow::-webkit-scrollbar-thumb {
    background-color: var(--background-color-tertiary);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid var(--background-color-secondary);  /* creates padding around scroll thumb */
}


.inputContainer {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* background: var(--background-color); */
    box-sizing: border-box;

    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    padding: 10px;
}


.hiddenButton {
    filter: opacity(0%);
    user-select: none;
    cursor: auto !important;
}
.hiddenButton * {
    filter: opacity(0%);
}

.betaTag {
    font-weight: 400;
    font-size: 0.7em;

    background: var(--primary-color);

    padding: 3px;
    color: var(--foreground-color);

    border-radius: 5px;
}


.parentContainer {
    width: 100%;

    display: flex;
}

.container {
    width: fit-content;
    max-width: 80%;

    font-size: 0.9em;

    border-radius: 5px;

    padding: 10px;

    text-wrap: wrap;
    white-space: pre-line;

    color: var(--foreground-color);
}
.container p {
    padding: 0;
    margin: 0;
    word-wrap: break-word;
}


.primary {
    background: var(--primary-color);
}
.secondary {
    background: #444;

    margin-left: auto;
}

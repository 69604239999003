
.page {
    pointer-events: none;

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    overflow: hidden;

    z-index: 1000;

    box-sizing: border-box;


    
    /* The color of text */
    --foreground-color: white;
    /* The color of background items */
    --background-color: rgb(20, 20, 20);
    --background-color-secondary: #0c000e;
    --background-color-tertiary: #230029;

    /* Brand color pallet */
    --primary-color: #8b00ac;
    --secondary-color: #8300c7;
    
    --primary-color-bright: #d101ec;
    --secondary-color-bright: #8600ce;
}

.container {
    position: absolute;

    bottom: 20px;
    right: 20px;

    
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 25px;

    pointer-events: auto;
}



.chatButton {
    cursor: pointer;
    
    height: 60px;
    width: 60px;

    border: solid 2px black;
    border-radius: 50%;
    
    -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,1);
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.5);
}
.chatButton img {
    height: 70%;
    width: 70%;

    filter: invert();
}

.primaryColor {
    background: var(--primary-color);
}
.grey {
    background: #333;
}


.container {
    border-radius: 5px;

    padding: 10px;

    text-wrap: wrap;
    white-space: pre-line;
    width: 10em;

    color: var(--foreground-color);
}
.container p {
    padding: 0;
    margin: 0;
    word-wrap: break-word;
}


.primary {
    background: var(--primary-color);
}
.primary:after {
    content: '';
    position: absolute;
    right: 15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 17px solid var(--primary-color);
    clear: both;
}

.secondary {
    background: #444;
}
.secondary:after {
    content: '';
    position: absolute;
    right: 15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 17px solid #444;
    clear: both;
}

